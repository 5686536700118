<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox ">
          <div class="ibox-title">
            <h5></h5>
            <div class="ibox-tools">
              <router-link to="addRoleEndpoint">
                <i class="fa fa-plus"></i> &nbsp;&nbsp; Add
              </router-link>
            </div>
          </div>
          <div class="ibox-content">
            <div class="table-responsive">
              <table
                class="table table-bordered table-hover"
                :class="dataTablesEndpoint"
              >
                <thead>
                  <tr>
                    <th>{{ endpointColumn.id }}</th>
                    <th>{{ endpointColumn.roleName }}</th>
                    <th>{{ endpointColumn.url }}</th>
                    <th>{{ endpointColumn.method }}</th>
                    <th>{{ endpointColumn.action }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="gradeX"
                    v-for="(value, key) in endpointData"
                    :key="key"
                  >
                    <td style="width: 10%;">{{ value.id }}</td>
                    <td style="width: 12%;">{{ value.roleName }}</td>
                    <td style="width: 15%;">{{ value.url }}</td>
                    <td style="width: 12%;">{{ value.method }}</td>
                    <td style="width: 2%;">
                      <i class="fas fa-user-cog"></i>
                      <a
                        class="dropdown-toggle"
                        data-toggle="dropdown"
                        href="#"
                      >
                        <i class="fa fa-wrench"></i>
                      </a>
                      <ul class="dropdown-menu dropdown-user">
                        <li>
                          <router-link
                            :to="{
                              path: '/updateRoleEndpoint',
                              query: { id: value.id },
                            }"
                            class="dropdown-item"
                            >Edit</router-link
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            data-toggle="modal"
                            data-target="#myModal4"
                            @click="addModalData(value)"
                            >Delete</a
                          >
                        </li> 
                      </ul>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                     <th>{{ endpointColumn.id }}</th>
                    <th>{{ endpointColumn.roleName }}</th>
                    <th>{{ endpointColumn.url }}</th>
                    <th>{{ endpointColumn.method }}</th>
                    <th>{{ endpointColumn.action }}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <DeleteModal :data="data"></DeleteModal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "../plugin/AxiosService.js";
import Constant from "../plugin/Constant.js";
import DataTableService from "../plugin/DataTableService";
import DeleteModal from "../components/common/DeleteModal";
import EventBus from "../plugin/event-bus";

export default {
  data() {
    return {
      endpointColumn: {
        id: "Id",  
        roleName: "Role Name",
        url: "URL",
        method: "Method",
        action: "Action"
      },
      endpointData: [],
      dataTablesEndpoint: "dataTables-Endpoint",
      exportTitle: "Role Endpoint",
      data: {
        title: "Role Endpoint",
        name: "",
        id: 0,
        url: Constant.url.ROLE_ENDPOINT_DELETE,
      },
    };
  },
  components: {
    DeleteModal,
  },
  created: function() {
    this.fetchendpointData();
    this.deleteEvent();
  },
  mounted: function() {
    DataTableService.loadDataTable(this.dataTablesEndpoint, this.exportTitle);
  },
  methods: {
    fetchendpointData: function() {
      var self = this;
      var responseCode = Constant.data.RESPONSE_CODE;
      AxiosService.get(Constant.url.ROLE_ENDPOINT_ALL).then((result) => {
        self.endpointData = result;
      });
    },
    addModalData: function(value) {
      this.data.name = value.roleName;
      this.data.id = value.id;
    },
    deleteEvent: function() {
      const self = this;
      EventBus.$on(Constant.data.DELETE_EVENT, (data) => {
        if (data) {
          self.fetchendpointData();
          DataTableService.reLoadDataTable(self.dataTablesEndpoint);
          DataTableService.loadDataTable(
            self.dataTablesEndpoint,
            self.exportTitle
          );
        }
      });
    },
  },
  beforeDestroy() {
    EventBus.$off(Constant.data.DELETE_EVENT);
  },
};
</script>
<style scoped>
.ibox-tools a {
  cursor: pointer;
  margin-left: 5px;
  color: #0f0f0f !important;
}
a {
  color: #636363;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
</style>
