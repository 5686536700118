import { render, staticRenderFns } from "./RoleEndpoint.vue?vue&type=template&id=084a3216&scoped=true"
import script from "./RoleEndpoint.vue?vue&type=script&lang=js"
export * from "./RoleEndpoint.vue?vue&type=script&lang=js"
import style0 from "./RoleEndpoint.vue?vue&type=style&index=0&id=084a3216&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "084a3216",
  null
  
)

export default component.exports